import React from "react";
import Layout from "@/components/layout";
import HeaderOne from "@/components/header-one";
import MenuContextProvider from "@/context/menu-context";
import PageHeader from "@/components/page-header";
import Footer from "@/components/footer";
import { graphql } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";
import Link from "@/components/link";

const Blog = ({data}) => {
  console.log(data)
 const posts = data.allMarkdownRemark.nodes;

  return (
    <MenuContextProvider>
      <Layout PageTitle="Blog Page">
        <HeaderOne />
        <PageHeader title="Blog" name="Blog" />
        <section className="blog_share_area section_padding blog-page">
          <Container>
            <Row>
              {posts.map((post, index) => (
                <Col lg={4} md={6} sm={12} key={`blog-one-${index}`}>
                  <div className="blog_share_box">
                    <div className="bl_share_img">
                      {/* <Img src={image} alt={title} className="img-fluid" /> */}
                      {/* <span className="blog_date">{date}</span> */}
                    </div>
                    <div className="blog_share_details">
                      <h1>
                        <Link href={"/blog-details/" + post.frontmatter.slug} key={post.id}>{post.frontmatter.title}</Link>
                      </h1>
                      <p>{post.frontmatter.title}</p>
                    </div>
                  </div>      
              </Col>
              ))}
            </Row>
          </Container>
        </section>
          <Footer />
      </Layout>
    </MenuContextProvider>
  );
};

export default Blog;

export const query = graphql`
  query ProjectsPage2 {
    allMarkdownRemark {
      nodes {
        frontmatter {
          slug          
          title
        }
        id
      }
    }
  }`;